<template>
  <div class="data-card fliccard">
    <div class="data-card_header d-flex spacebetween">
      <div class="textblack-medium24">{{ title }}</div>
      <div class="d-flex">
        <div
          class="data-card_filter"
          v-for="(item, index) in filterItems"
          :key="item.prop"
        >
          <template v-if="item.formtype == 'input'">
            <el-input
              :type="item.type || 'text'"
              :placeholder="item.placeholder"
              v-model="filterForm[item.prop]"
              :style="item.object.style || ''"
              :disabled="item.object.disabled || false"
              :key="index"
            />
          </template>
          <template v-else-if="item.formtype == 'select'">
            <el-select
              :placeholder="item.placeholder"
              v-model="filterForm[item.prop]"
              :style="item.object.style || ''"
              :disabled="item.object.disabled || false"
              :clearable="item.object.clearable || false"
              :multiple="item.object.multiple || false"
              :loading="item.object.loading || false"
              :key="item.formtype + index"
            >
              <el-option
                v-for="option in item.options" :key="option[item.object.key || 'value']"
                :label="option[item.object.name || 'name']"
                :value="option[item.object.key||'value']"
                :disabled="option.disabled || false"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.formtype == 'daterange'">
            <el-date-picker
              v-model="filterForm[item.prop]"
              type="daterange"
              range-separator="-"
              :start-placeholder="item.object.startPlaceholder || 'Start Date'"
              :end-placeholder="item.object.endPlaceholder || 'End Date'"
              :style="item.object.style || ''"
              :picker-options="item.object.pickerOptions"
              value-format="yyyy-MM-dd"
              @change="(val) => $emit('formchange', item, val)"
            >
            </el-date-picker>
          </template>
        </div>
      </div>
    </div>
    <div v-if="dataObj" class="data-card_items d-flex flex-wrap">
      <div class="data-card_item" v-for="item in dataItems" :key="item.prop">
        <div class="data-card_num">{{ dataObj[item.prop] }}</div>
        <div class="data-card_tips">{{item.label}}</div>
      </div>
    </div>
    <slot name="main"></slot>
  </div>
</template>

<script>
export default {
  props:{
    title: {
      type: String,
      default: ''
    },
    filterItems: {
      type: Array,
      default: () => []
    },
    dataItems: {
      type: Array,
      default: () => []
    },
    dataObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      filterForm: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      let obj = {}
      this.filterItems.forEach((f) => {
        if (f) {
          if (Object.hasOwn(f, 'value')) {
            obj[f.prop] = f.value
          } else {
            obj[f.prop] = ''
          }
        }
      })
      this.$set(this, 'filterForm', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.data-card {
  width: 100%;
  padding-bottom: 45px;
  margin-bottom: 24px;
  &_header {
    padding: 24px 24px 0;
    box-sizing: border-box;
    width: 100%;
  }
  &_items {
    padding: 0 30px;
  }
  &_item {
    width: calc(100% / 5);
    margin-top: 50px;
    flex: none;
  }
  &_num {
    font-size: 40px;
    color: $text-color-black;
    margin-bottom: 10px;
  }
  &_tips {
    font-size: 16px;
    color: $text-color-secondarygray;
    line-height: 24px;
  }
}
</style>