<template>
  <div class="barchart" ref="barChart"></div>
</template>

<script>
import echarts from '@/utils/echart'
export default {
  props: {
    barData: {
      type: Array,
      default: () => []
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    yData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    initBarChart () {
      this.barChart = echarts.init(this.$refs.barChart)
      const series = []
      this.barData.forEach((d) => {
        series.push({
          name: d.name,
          type: 'bar',
          barWidth: 10,
          label: {
            position: 'right',
            show: true,
            fontSize: 10
          },
          data: d.data
        })
      })
      const option = {
        legend: {
          icon: 'rect',
          left: 'center',
          bottom: 0,
          itemGap: 30,
          show: series.length > 1
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '9%',
          top: 0,
          containLabel: true
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            fontSize: 12
            //verticalAlign: 'bottom'
          },
          data: this.yData
        },
        series
      }
      this.barChart.setOption(option)
    },
    setEchartsOption () {
      const series = []
      this.barData.forEach((d) => {
        series.push({
          name: d.name,
          type: 'bar',
          barWidth: 10,
          label: {
            position: 'right',
            show: true,
            fontSize: 10
          },
          data: d.data
        })
      })
      const option = {
        yAxis: {
          data: this.yData
        },
        series
      }
      if (this.barChart) {
        this.barChart.setOption(option)
      }
    },
    drawBarChart () {
      if (this.barChart) this.setEchartsOption()
      else this.initBarChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.barchart {
  width: 100%;
  height: 100%;
}
</style>