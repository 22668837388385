<template>
  <div class="linechart" ref="lineChart"></div>
</template>

<script>
import echarts from '@/utils/echart'
export default {
  props: {
    getDataFunc: {
      type: [Function, String]
    }
  },
  data () {
    return {
      xData: [],
      y1Data: [],
      y2Data: []
    }
  },
  methods: {
    initLineChart () {
      this.lineChart = echarts.init(this.$refs.lineChart)
      const option = {
        grid: {
          bottom: 70,
          left: 50,
          right: 60
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765'
            }
          }
        },
        legend: {
          data: ['Inflight Number', 'BOR'],
          left: 35,
          bottom: 0,
          icon: 'rect',
          itemGap: 16,
          itemWidth: 21,
          itemHeight: 8
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: this.xData,
            splitLine: {
              show: true,
              interval: 1
            }
          }
        ],
        yAxis: [
          {
            name: 'Inflight Number',
            type: 'value'
          },
          {
            name: 'BOR(%)',
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Inflight Number',
            type: 'line',
            lineStyle: {
              width: 1
            },
            emphasis: {
              focus: 'series'
            },
            data: this.y1Data
          },
          {
            name: 'BOR',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: {
              width: 1
            },
            emphasis: {
              focus: 'series'
            },
            data: this.y2Data
          }
        ],
        color: ['#A155B9', '#165BAA']
      }
      this.lineChart.setOption(option)
    },
    async initData () {
      if (!this.getDataFunc) return
      try {
        const res = await this.getDataFunc()
        if (res.code >= 200 && res.code < 300) {
          this.xData = []
          this.y1Data = []
          this.y2Data = []
          res.data.forEach((d) => {
            this.xData.push(d.date)
            this.y1Data.push(d.occupied)
            this.y2Data.push(d.bor)
          })
        }
      } catch (error) {
        
      }
    },
    async drawLineChart () {
      await this.initData()
      if (this.lineChart) {
        const option = {
          series: [
            {
              name: 'Inflight Number',
              data: this.y1Data
            },
            {
              name: 'BOR',
              data: this.y2Data
            }
          ],
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              // prettier-ignore
              data: this.xData,
              splitLine: {
                show: true,
                interval: 1
              }
            }
          ]
        }
        this.lineChart.setOption(option)
      } else {
        this.initLineChart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.linechart {
  width: 100%;
  height: 100%;
}
</style>