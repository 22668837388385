import * as echarts from 'echarts/core'
import {
  LineChart,
  PieChart,
  BarChart
} from 'echarts/charts'

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,          
  DatasetComponent,
  TransformComponent,
  LegendComponent
} from 'echarts/components'
import {
  LabelLayout,
  UniversalTransition
} from 'echarts/features'
import { SVGRenderer } from 'echarts/renderers'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  LineChart,
  PieChart,
  BarChart,
  LabelLayout,
  UniversalTransition,
  SVGRenderer
])

export default echarts
