<template>
  <div class="piechart" ref="pieChart"></div>
</template>

<script>
import echarts from '@/utils/echart'
export default {
  props: {
    pieData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    initPieChart () {
      this.pieChart = echarts.init(this.$refs.pieChart)
      const option = {
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
          icon: 'circle'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Patient Distribution',
            type: 'pie',
            radius: '80%',
            data: this.pieData,
            label: {
              show: false
            },
            emphasis: {
              scaleSize: 30,
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        color: ['#627CD2', '#9ECA7F', '#F2C96B', '#DE6E6A', '#85BEDB', '#599F76',
          '#EC8A5D', '#9264AF', '#DC83C8', '#5971C0', '#9ECA7F', '#F2C96B', '#DE6E6A',
          '#85BEDB', '#599F76'
        ]
      }
      this.pieChart.setOption(option)
    },
    drawPieChart () {
      if (this.pieChart) {
        const option = {
          series: [
            {
              name: 'Patient Distribution',
              data: this.pieData
            }
          ]
        }
        this.pieChart.setOption(option)
      } else {
        this.initPieChart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.piechart {
  width: 100%;
  height: 100%;
}
</style>