export default {
  todayDataItems: [
    {
      prop: 'admitted',
      label: 'Inflight Number'
    },
    {
      prop: 'admission',
      label: 'New Admission'
    },
    {
      prop: 'discharged',
      label: 'Discharge'
    },
    {
      prop: 'u_turned',
      label: 'U-turned'
    },
    {
      prop: 'demise',
      label: 'Demise'
    }
  ],
  historyFilterItems: [
    {
      prop: 'date',
      formtype: 'daterange',
      label: '',
      placeholder: 'Start Date',
      object: {
        style: 'width: 303px;',
        startPlaceholder: 'Start Date',
        endPlaceholder: 'End Date',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        }
      }
    }
  ],
  historyDataItems: [
    {
      prop: 'referral',
      label: 'Total Referral'
    },
    {
      prop: 'admission',
      label: 'Total Admission'
    },
    {
      prop: 'discharged',
      label: 'Total Discharge'
    },
    {
      prop: 'u_turned',
      label: 'Total U-turned'
    },
    {
      prop: 'demise',
      label: 'Total Demise'
    },
    {
      prop: 'los',
      label: 'Total Bed-days Served'
    },
    {
      prop: 'average_los',
      label: 'Average LOS'
    }
  ],
  dischargeTabs: [
    {
      name: 'Time Frame Selected',
      id: 'time'
    },
    {
      name: 'Current Patients',
      id: 'occupied'
    }
  ],
  mohHeaderTabs: [
    {
      name: 'TCF view',
      id: 'TCF'
    },
    {
      name: 'PHI view',
      id: 'PHI'
    }
  ]
}