<template>
  <div class="dashboard">
    <template v-if="!isOther">
      <div class="dashboard_header d-flex spacebetween mb20">
        <div class="d-flex">
          <template v-if="isMOH">
            <div
              class="dashboard_tab d-flex dashboard_headertab"
              v-for="item in mohHeaderTabs"
              :key="item.id"
              :class="{'active': item.id == currMOHHeaderTab}"
              @click="mohTabChangeHandler(item)"
            >
              {{ item.name }}
            </div>
            <el-select
              placeholder="Institution"
              v-model="mohViewInstitutionId"
              style="width: 163px;margin-left: 15px;"
              @change="() => getDataFromServer('today')"
            >
              <el-option
                v-for="option in mohViewInstitutionList" :key="option.id"
                :label="option.name"
                :value="option.id"
                :disabled="computedDisabled(option)"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="d-flex">
          <div class="textprimary">View data for</div>
          <div class="dashboard_header_select">
            <el-select
              placeholder="Institution"
              v-model="institutionId"
              style="width: 140px;margin-left: 15px;"
              @change="() => getDataFromServer('today')"
            >
              <el-option
                v-for="option in institutionList" :key="option.id"
                :label="option.name"
                :value="option.id"
                :disabled="computedDisabled(option)"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <DataItemsCard
        title="Today"
        :data-items="todayDataItems"
        :data-obj="todayData"
      />
      <DataItemsCard
        title="Historical Data"
        :data-items="historyDataItems"
        :data-obj="historyData"
        :filter-items="historyFilterItems"
        class="dashboard_history"
        @formchange="formchangeHandler"
      >
        <div v-if="userInstitution != 'PHI' && currMOHHeaderTab != 'PHI'" class="dashboard_main" slot="main">
          <div class="dashboard_title textblack-medium24">Inflight Number and BOR by Date</div>
          <div class="dashboard_line">
            <LineChart :getDataFunc="getDataFunc" ref="lineChart" />
          </div>
          <div class="dashboard_discharge">
            <div class="dashboard_title textblack-medium24">Discharge Statistics</div>
            <div class="dashboard_tabs_wrapper d-flex">
              <div class="dashboard_tabs d-flex">
                <div
                  class="dashboard_tab d-flex"
                  v-for="item in dischargeTabs"
                  :key="item.id"
                  :class="{'active': item.id == currDischargeTab}"
                  @click="dischargeTabChangeHandler(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div v-if="currDischargeTab == 'time'" class="dashboard_subtitle">
              Patient Distribution by Actual Discharge Destination from {{dateStr(startDate)}} to {{dateStr(endDate)}}
            </div>
            <div v-else class="dashboard_subtitle">
              Planned Discharge Destination for Current Patients
            </div>
            <div class="dashboard_pie">
              <PieChart :pieData="dischargePieData" ref="pieChart" />
            </div>
            <div class="dashboard_bar" :style="`height:${dischargeBarHeight}px;`">
              <BarChart
                :barData="dischargeBarData"
                :yData="dischargeBarYData"
                ref="barChart"
                key="actual"
              />
            </div>
            <div class="dashboard_divide"></div>
            <div class="dashboard_subtitle mb30">
              Average LOS by Actual Discharge Destination from {{dateStr(startDate)}} to {{dateStr(endDate)}}
            </div>
            <div class="dashboard_bar" :style="`height:${dischargeLOSBarHeight}px;`">
              <BarChart
                :barData="dischargeLOSBarData"
                :yData="dischargeBarYData"
                ref="losBarChart"
                key="los"
                :showLegend="false"
              />
            </div>
          </div>
        </div>
      </DataItemsCard>
    </template>
    <div v-else class="flic-empty">No Data</div>
  </div>
</template>

<script>
import DataItemsCard from './components/DataItemsCard'
import LineChart from './components/LineChart'
import BarChart from './components/BarChart'
import PieChart from './components/PieChart'
import {
  getInstitutionList,
  getDashboardPatient,
  getDashboardBed,
  getDashboardDischarge
} from '@/services'
import Utils from '@/utils/Utils'
import { mapState } from 'vuex'
import jsonData from './jsonData'
export default {
  data () {
    return {
      institutionList: [],
      institutionId: '',
      historyData: null,
      todayData: null,
      userInstitution: '',
      startDate: '',
      endDate: '',
      ...jsonData,
      getDataFunc: '',
      dischargePieData: [],
      dischargeBarYData: [],
      dischargeBarData: [],
      dischargeLOSBarData: [],
      currDischargeTab: 'time',
      isMOH: false,
      mohViewInstitutionList: [],
      mohViewInstitutionId: '',
      currMOHHeaderTab: 'TCF',
      isOther: false,
      dischargeBarHeight: 60,
      dischargeLOSBarHeight: 45
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    computedDisabled () {
      return function (option) {
        return Object.hasOwn(option, 'active') && !option.active
      }
    },
    dateStr () {
      return function (date) {
        return date.split('-').join(' / ')
      }
    }
  },
  components: {
    DataItemsCard,
    LineChart,
    BarChart,
    PieChart
  },
  created () {
    this.initData()
  },
  mounted () {
    this.getDataFromServer('today')
  },
  methods: {
    async initInstitutionList (kind_eq, type) {
      try {
        const res = await getInstitutionList({kind_eq}, false)
        if (res.data.institutions && res.data.institutions.length > 0) {
          if (type == 'mohview') {
            this.mohViewInstitutionList = res.data.institutions
            this.mohViewInstitutionId = ''
            this.mohViewInstitutionList.unshift({
              id: '',
              name: 'All ' + kind_eq + 's'
            })
          } else {
            this.institutionList = res.data.institutions
            this.institutionList.unshift({
              id: '',
              name: 'All ' + kind_eq + 's'
            })
          }
        }
      } catch (error) {}
    },
    initData() {
      let kind_eq = 'TCF'
      if (this.user && this.user.institution) {
        const kind = this.user.institution.kind
        if (kind == 'TCF') {
          kind_eq = 'PHI'
        } else if (kind == 'MOH') {
          this.isMOH = true
          kind_eq = 'PHI'
          this.initInstitutionList('TCF', 'mohview')
        } else if (kind == 'PHI') {
          kind_eq = 'TCF'
        } else {
          this.isOther = true
        }
        this.userInstitution = kind
        this.initInstitutionList(kind_eq)
      }
      this.startDate = Utils.getLaterDate(new Date(), -7)
      this.endDate = Utils.getTime(new Date())
      this.historyFilterItems[0].value = [this.startDate, this.endDate]
    },

    async getDataFromServer (type) {
      if (this.isOther) return
      let params = {}
      if (this.isMOH) {
        if (this.currMOHHeaderTab == 'TCF') {
          params = {
            to_institution_id: this.mohViewInstitutionId,
            from_institution_id: this.institutionId
          }
        } else {
          params = {
            from_institution_id: this.mohViewInstitutionId,
            to_institution_id: this.institutionId
          }
        }
      } else if (this.userInstitution == 'TCF') {
        params.from_institution_id = this.institutionId
      } else if (this.userInstitution == 'PHI') {
        params.to_institution_id = this.institutionId
      }
      if (type != 'discharge') this.initDashboardPatient(type, params)
      if (this.userInstitution == 'TCF' || this.currMOHHeaderTab == 'TCF') {
        if (type != 'discharge') {
          this.$set(this, 'getDataFunc', () => getDashboardBed({
            start_date: this.startDate,
            end_date: this.endDate,
            ...params
          }, false))
        }
        await this.initDashboardDischarge()
        this.$nextTick(() => {
          if (this.$refs.lineChart && type != 'discharge') this.$refs.lineChart.drawLineChart()
          if (this.$refs.pieChart) this.$refs.pieChart.drawPieChart()
          if (this.$refs.barChart) this.$refs.barChart.drawBarChart()
          if (this.$refs.losBarChart) this.$refs.losBarChart.drawBarChart()
        })
      }
    },

    async initDashboardPatient(type, obj) {
      try {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate
        }
        if (obj) {
          Object.assign(params, obj)
        }
        const res = await getDashboardPatient(params)
        if (res.code >= 200 && res.code < 300) {
          if (type == 'today') {
            this[type + 'Data'] = res.data[type]
            this.$set(this, type + 'Data', res.data[type])
          }
          this.historyData = res.data.history
          this.$set(this, 'historyData', res.data.history)
        }
      } catch (error) {}
    },
    async initDashboardDischarge () {
      try {
        const res = await getDashboardDischarge({
          from_institution_id: this.institutionId,
          start_date: this.startDate,
          end_date: this.endDate,
          tab: this.currDischargeTab
        })
        if (res.code >= 200 && res.code < 300) {
          const keys = Object.keys(res.data)
          this.dischargePieData = []
          this.dischargeBarYData = []
          this.dischargeBarData = []
          let arr1 = []
          let arr2 = []
          let arr3 = []
          keys.forEach((k) => {
            this.dischargePieData.push({
              name: k,
              value: res.data[k].percent
            })
            if(this.currDischargeTab == 'time') arr2.push(res.data[k].actual)
            arr1.push(res.data[k].planned)
            arr3.push(res.data[k].average_los)
            this.dischargeBarYData.push(k)
          })
          this.dischargeBarHeight = 50 + 45 * (arr1.length)
          this.dischargeLOSBarHeight = 20 + 30 * (arr3.length)
          this.dischargeLOSBarData = [
            {
              name: 'Average LOS',
              data: arr3
            }
          ]
          this.dischargeBarData = [
            {
              name: 'Planned',
              data: arr1
            }
          ]
          if(this.currDischargeTab != 'occupied') {
            this.dischargeBarData.unshift({
              name: 'Actual',
              data: arr2
            })
          }
        }
      } catch (error) {}
    },
    dischargeTabChangeHandler (item) {
      this.currDischargeTab = item.id
      this.getDataFromServer('discharge')
    },
    async mohTabChangeHandler (item) {
      this.currMOHHeaderTab = item.id
      let kind_eq = 'TCF'
      if (item.id == 'TCF') kind_eq = 'PHI'
      this.initInstitutionList(kind_eq, '')
      this.initInstitutionList(item.id, 'mohview')
      this.getDataFromServer('today')
    },
    formchangeHandler (item, val) {
      if (Array.isArray(val) && item.formtype == 'daterange') {
        this.startDate = val[0]
        this.endDate = val[1]
      }
      this.getDataFromServer()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &_title {
    padding: 24px 24px 0;
    box-sizing: border-box;
    border-top: $border-style;
    margin-top: 64px;
  }
  &_divide {
    padding-top: 60px;
    border-bottom: $border-style;
    box-sizing: border-box;
  }
  &_line {
    width: 100%;
    padding: 0 24px;
    height: 375px;
    box-sizing: border-box;
  }
  &_tabs_wrapper {
    width: 100%;
    padding: 0 24px;
    margin-top: 24px;
    box-sizing: border-box;
  }
  &_tab {
    height: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    color: #000;
    border: 1px solid #D9D9D9;
    border-left: none;
    cursor: pointer;
    &:first-child {
      border-left: 1px solid #D9D9D9;
    }
    &.active,
    &:hover {
      color: #fff;
      background: #565C63;
      border-color: #565C63;
      // border: none;
    }
  }
  &_headertab {
    border: none;
    background: #fff;
    &:first-child {
      border-left: none;
    }
  }
  &_subtitle {
    color: $text-color-black;
    font-size: 16px;
    padding: 24px 24px 0;
    box-sizing: border-box;
  }
  &_pie {
    width: 690px;
    margin: 60px auto;
    height: 370px;
    box-sizing: border-box;
  }
  &_bar {
    width: calc(100% / 2);
    padding: 0 70px;
    height: 550px;
    box-sizing: border-box;
  }
}
</style>
